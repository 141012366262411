.splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.splash-animation-container {
    height: 50vh;
}

.splash-logo {
    font-size: 2.5em;
    text-decoration: none;
}
.splash-logo-name {
    color: #4da8da;
    font-family: "Agustina Regular", cursive;
    font-weight: bold;
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-variant-ligatures: no-common-ligatures;
    padding: 0 20px;
}